.main-green {
  color: #029c45 !important;
}

.dark-green {
  color: #00461c !important;
}

.white {
  color: #fff;
}

.fruit-color {
  color: #ffb74d;
}

.border-fruit-color {
  border-color: #ffb74d;
}

.vegetable-color {
  color: #003314;
}

.border-vegetable-color {
  border-color: #003314;
}

.nav-link:active .nav-link:focus .nav-line-tabs {
  border-bottom: 3px solid #029c45;
}

.system-blue-link {
  color: #1a73e8;
}

.hover-shift-right:hover {
  transition: transform 0.2s ease;
}
